import './404.css';

const Error404 = (props) => {
    let path = window.location.href;
    return (
        <div className={"cropped-page"}>
            <title>404 Not Found</title>
            <h1 className={"error-404-heading"}>404</h1>
            <p className={"error-404-text"}>The page at <span className={"404-path"}>{path}</span> does not exist or
                failed to load.</p>
        </div>
    );
}

export {Error404};
