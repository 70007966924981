import './loading.css';

const Loader = (props) => {
    return (
        <div className={"cropped-page"}>
            <title>blog.lstuma.dev</title>
            <div className={"loader"}></div>
        </div>
    );
}

export {Loader};
