import './home.css';
import { Link } from 'react-router-dom';
import { collect, collect_metadata, collect_b64 } from "../api/page";
import { metadata } from "../api/blog";
import React, { useEffect, useState } from "react";
import { Loader } from "./loading";
import DOMPurify from "dompurify";
import { Error404 } from "./404";
import { ArticlePreview } from "./article_preview";

const Splash = (props) => {
    /*
     * Splash screen when going to homepage.
     */

    return (
        <div className="home-splash cropped-page">
            <img src={process.env.PUBLIC_URL+"img/clouds.jpg"} className={"home-splash-bg"} />
            <img className={"home-splash-profile"} src={process.env.PUBLIC_URL+"img/profile_transparent.png"} />
        </div>
    );
}

const Home = (props) => {
    // get page to render from url
    let page = window.location.href.split('/').at(-1);
    // remove any get parameters and html id's
    page = page.split('?')[0].split('#')[0];

    const [metaData, setMetaData] = useState(undefined);

    useEffect(() => {
        const load = async () => {
            let data = await metadata();
            setMetaData(data);
        };
        load();
    }, []);

    if (metaData === undefined) {
        return (
            <Loader/>
        );
    }else if (metaData !== "error") {
        return (
            <>
            <Splash />
            <div className={"home"}>
                <title>blog.lstuma.dev</title>
                <ArticlePreview meta={metaData}/>
            </div>
            </>
        );
    } else if (metaData === "error") {
        return (
            <Error404/>
        );
    }
}

export {Home};
