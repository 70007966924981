import {replace} from "react-router-dom";

const pages_api_base = "https://api.lstuma.dev:3000/pages/";
const api_base = "https://api.lstuma.dev:3000/api/pages/"

const is_locked = (raw_rsp) => {
    /*
        Returns true if provided page_data is locked
     */
    try {
        let lock_data = JSON.parse(raw_rsp)
        return (lock_data.detail === "Wrong lock")
    }
    catch (error) {
        return false
    }
}

const verify_password = async (password, html_link) => {
    /*
        Tests whether password is valid
     */
     try {
        let res = await fetch(api_base+"verify/lock"+"?path="+btoa(html_link)+"&lock="+password);
        // check if response is valid
        if (res.status !== 200) {
            throw new Error("Error in server response: " + res.statusText);
        }
        let raw_rsp = await res.text();
        let data = JSON.parse(raw_rsp)
        if(data.status === "verified") {
            console.log("password verified (lock)")

            // add password to local storage
            console.log("saving password to localstorage page-password-"+btoa(html_link));
            localStorage.setItem("page-password-"+btoa(html_link), password);
            return true
        }
        console.log("password invalid (lock)")
        return false
    } catch (error) {
        throw new Error("Failed to verify password for lock: " + error);
    }
}

const verify_master_password = async (password) => {
    /*
        Checks if password is a master password
     */
    try {
        let res = await fetch(api_base+"verify/master"+"?lock="+password);
        // check if response is valid
        if (res.status !== 200) {
            throw new Error("Error in server response: " + res.statusText);
        }
        let raw_rsp = await res.text();
        let data = JSON.parse(raw_rsp)
        if(data.status === "verified") {
            console.log("password verified (master password)")

            // add password to local storage
            console.log("saving master password to localstorage page-master-password");
            localStorage.setItem("page-master-password", password);
            return true
        }
        console.log("password invalid (master password)")
        return false
    } catch (error) {
        throw new Error("Failed to verify password for lock: " + error);
    }
}

const collect_b64 = async (page_b64) => {
    /*
        Gets page but page is b64 encoded
     */
    try {
        let page = atob(page_b64);
        return await collect(page);
    } catch (error) {
        throw new Error("Could not gather page (b64): " + error);
    }
}

const collect = async (page) => {
    /*
        Gets page and any additional metadata
     */

    // get metadata
    let data = await collect_metadata(page);

    // make sure that html_link exists and is a valid link and not whitespace
    if ('html_link' in data && data.html_link.trim().length !== 0) {
        // check if a lock password is saved for the url
        let storage_item = "page-master-password";
        let master_password = localStorage.getItem(storage_item)
        let password = null;
        // if master password is found, use it for authentication
        if(master_password !== null) password = master_password
        // if master password is not found, try to use page password for authentication
        else {
            storage_item = "page-password-"+btoa(data.html_link);
            password = localStorage.getItem(storage_item);
        }

        let args = password?"?lock="+password:"";
        // fetch page html
        let res = await fetch(pages_api_base+data.html_link+args);
        // check if response is valid
        if (res.status !== 200) {
            throw new Error("Could not gather html for page: " + res.statusText);
        }
        data.raw_rsp = await res.text();
        if(is_locked(data.raw_rsp)) {
            if(password !== null) {
                console.error("password incorrect");
                console.log("removing incorrect password from localstorage")
                localStorage.removeItem(storage_item)
            } else {
                console.error("page requires lock")
            }
        }
        data.html = data.raw_rsp;
    } else {
        throw new Error("Missing link to gather page html");
    }

    return data;

}

const collect_metadata = async (page) => {
    /*
        Gets page metadata
     */

    // fetch metadata
    let res = await fetch(pages_api_base+page+".json");
    if (res.status !== 200) {
        throw new Error("Could not get metadata for page: " + res.statusText);
    }

    try {
        // parse json metadata
        let data = res.json();
        return data;
    } catch (error) {
        throw new Error("Page does not contain metadata: " + error);
    }
}

const search_tag = async (tag) => {

}

export { collect, collect_b64, collect_metadata, api_base, pages_api_base, is_locked, verify_password, verify_master_password };