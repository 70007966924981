import { HiOutlineLockClosed } from "react-icons/hi";
import { FaArrowRight } from "react-icons/fa6";
import './lock.css';
import { is_locked, tryUnlock, verifyPassword } from "../api/blog";
import { useState } from "react";


const Lock = (props) => {
    const [msg, setMsg] = useState(null);

    const submit = async() => {
        let notify_msg = document.querySelector("#msg-notify");
        notify_msg.classList.remove("show");
        // grab password
        let password = document.querySelector("#password").value;
        // check if password is a correct
        let correct = await tryUnlock(password);
        if(!correct)
        {
            setMsg("Incorrect password!");
            notify_msg.classList.add("show");
            return
        }
        // reload page (will send password and should load page successfully)
        notify_msg.classList.add("success");
        setMsg("Success!");
        notify_msg.classList.add("show");
        props.reloadHook();
    }
    return (
        <div className={"cropped-page"}>
            <title>Locked Page</title>
            <div className={"lock-container"}>
                <h1 className={"lock-heading"}>This page is locked</h1>
                <div className={"lock-password-container"}>
                    <HiOutlineLockClosed size={"2.25rem"} className={"lock-password-icon"}/>
                    <form action="#" onSubmit={(event) => { event.preventDefault(); submit(); }}>
                        <input type={"password"} name={"lock"} className={"lock-password"} id={"password"} placeholder={"●●●●●●●●●●●●●●●●●●●●●●●●●●●●●●●●●●●●●●"} />
                    </form>
                    <FaArrowRight size={"1.75rem"} className={"lock-password-submit"} onClick={() => { submit(); }}/>
                </div>
                <p className={"lock-password-msg-notify"} id={"msg-notify"}>{msg!==null?msg:""}</p>
            </div>
        </div>
    );
}

export {Lock};
