const API_BASE = "http://localhost:8000/api.lstuma.dev";

const is_locked = async (uid) => {
    /**
     * Returns true if provided page_data is locked
     */
    let data = await fetch(API_BASE + '/blog/is_locked/' + uid).then(response => response.json());
    if (data.status === "success") {
        return data.locked;
    } else {
        throw new Error(data.details);
    }
}

const metadata = async () => {
    /**
     * Returns metadata of all blog posts
     */
    let data = await fetch(API_BASE + '/blog/metadata').then(response => response.json());
    if (data.status === "success") {
        return data.metadata;
    } else {
        throw new Error(data.details);
    }
}

const getPost = async (uid) => {
    /**
     * Returns post data of the provided uid
     */
    let password = localStorage.getItem("master-password");
    if (!password) {
        // If password is not set, set it to empty string
        password = "";
    }
    let data = await fetch(API_BASE + '/blog/' + uid + "?lock=" + password).then(response => response.json());
    if (data.status === "success") {
        return data.post;
    } else if (data.details == "Wrong lock") {
        throw new Error("Wrong lock");
    } else {
        throw new Error(data.details);
    }
}

const tryUnlock = async (password) => {
    /**
     * Returns true if provided password is correct
     */
    let data = await fetch(API_BASE + '/blog/unlock?password=' + password).then(response => response.json());
    if (data.status === "success") {
        localStorage.setItem("master-password", password);
        return data.unlocked;
    } else {
        throw new Error(data.details);
    }
}

const verifyPassword = async() => {
    /**
     * Verifies the password stored in localStorage
     */
    let password = localStorage.getItem("master-password");
    if (!password) {
        return false;
    }
    if (tryUnlock(password)) {
        return true;
    } else {
        localStorage.removeItem("master-password");
        return false;
    }
}

export { is_locked, metadata, getPost, tryUnlock, verifyPassword };