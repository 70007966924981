import './home.css';
import "./article_preview.css";
import {Link} from "react-router-dom";
import {useEffect, useState} from "react";

const ArticlePreviewCard = (props) => {
    const meta = props.meta;

    return (
        <div className={"card article-preview-container"}>
            { [0,1,2].map((n) => {
                return (
                    <div className={"article-preview-column"}>
                        {meta.filter((e, i)  => i % 3 === n).map((article) => {
                            return (
                                <Link to={"/blog/"+article.uid} key={article.title}>
                                <div className={"article-preview"}>
                                    <div className={"article-preview-meta"}>
                                        <p className={"article-preview-date-author"}>
                                            <span className={"article-preview-date"}>{article.date_pretty}</span>
                                            <span className={"article-preview-date-author-dot"}>·</span>
                                            <span className={"article-preview-author"}>{article.author}</span>
                                        </p>
                                        <p className={"article-preview-tags"}>{
                                            article.tags.map((tag, key) => (
                                                <Link to={"/tag/" + tag}><span key={key}
                                                               className={"article-preview-tag"}>{tag}</span></Link>
                                            ))
                                        }</p>
                                    </div>
                                    <h1 className={"article-preview-title"}>{article.title}</h1>
                                    <p className={"article-preview-description"}>{article.description}</p>
                                    <div className={"article-preview-bg"}></div>
                                </div>
                                </Link>
                                );
                        })}
                    </div>
                );
            })}
        </div>
    );
}

const ArticlePreviewCompact = (props) => {
    const meta = props.meta;

    return (
        <div className={"home-pages"}>
            {meta.map((page, key) => (
                <div className={"home-page-display"} key={key}>
                    <div className={"home-page-display-meta"}>
                        <div className={"home-page-display-meta-left"}>
                            <span className={"home-page-display-date"}>{page.date_pretty}</span><span
                            className={"prevent-select no-mobile"}>·</span><span
                            className={"home-page-display-author no-mobile"}>{page.author}</span>
                        </div>
                        <div className={"home-page-display-tags no-mobile"}>
                            {page.tags.map((tag, key) => (
                                <Link to={"/tag/" + tag}><span key={key}
                                                               className={"home-page-display-tag"}>{tag}</span></Link>
                            ))}
                        </div>
                    </div>
                    <h2 className={"home-page-display-title"}>
                        <Link to={"/blog/"+page.uid} key={key}>{page.title}</Link>
                    </h2>
                    <span className={"home-page-display-description"}>{page.description}</span>
                </div>
            ))}
        </div>
    );
}

const ArticlePreview = (props) => {
    const meta = props.meta;

    const [display, setDisplay] = useState("compact");

    useEffect(() => {
        // get display style for blog posts
        let _display = localStorage.getItem("article-display-type");
        if (_display != "card" && _display != "compact") {
            setDisplay("compact");
        } else {
            setDisplay(_display);
        }
    }, []);

    const changeDisplay = (event) => {
        let checkbox = document.getElementById("article-display-checkbox");
        if (checkbox.checked) {
            localStorage.setItem("article-display-type", "card");
            setDisplay("card");
        } else {
            localStorage.setItem("article-display-type", "compact");
            setDisplay("compact");
        }
    }

    if(display === "card") {
        return (
            <>
                <label className="article-display-switch  no-mobile">
                    <input type="checkbox" id={"article-display-checkbox"} onClick={changeDisplay} checked={display==="card"}/>
                    <span className="article-display-slider"></span>
                </label>
                <ArticlePreviewCard meta={meta}/>
            </>
        );
    } else {
        return (
            <>
                <label className="article-display-switch no-mobile">
                    <input type="checkbox" id={"article-display-checkbox"} onClick={changeDisplay} checked={display==="card"}/>
                    <span className="article-display-slider"></span>
                </label>
                <ArticlePreviewCompact meta={meta}/>
            </>
        );
    }

}

export {ArticlePreviewCard, ArticlePreviewCompact, ArticlePreview};