import './components.css';
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FaGithubAlt } from "react-icons/fa";
import { SiHackthebox } from "react-icons/si";

const ContactIcon = (props) => {
    return (
        <a href={props.link} className="contact-icon">{props.children}</a>
    );
}

const Contact = (props) => {
    return (
        <>
            <ContactIcon link={"https://github.com/lstuma/"}><FaGithubAlt size={"1.25rem"}/></ContactIcon>
            <ContactIcon link={"https://app.hackthebox.com/users/1226177"}><SiHackthebox size={"1.25rem"}/></ContactIcon>
        </>
    );
}

const Navbar = (props) => {
    const [animPos, setAnimPos] = useState(0);


    const anim_loop = async(pos) => {
        setAnimPos(pos);
        setTimeout(() => {anim_loop((++pos)%12)}, 100);
    };

    const gen_brand_text = (pos) => {
        let text = "/lstuma/blog";
        return (
            <>
                {text.slice(0, pos)}
                <span style={{color: "var(--secondary-accent-color)", fontFamily: "inherit"}}>
                    {text[pos]}
                </span>
                {text.slice(pos+1, 12)}
            </>
        );
    };

    useEffect(() => {
        anim_loop(0);
    }, []);

    return (
        <nav className="navbar">
            <div className="container" style={{justifyContent: "left"}}>
                <Link to={"/"}>
                    <h1 className={"navbar-brand prevent-select"}>
                        {gen_brand_text(animPos)}
                    </h1>
                </Link>
            </div>
            <div className="container no-mobile" style={{justifyContent: "right"}}>
                <Contact />
            </div>
        </nav>
    );
}

export { Navbar };
