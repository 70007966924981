import './page.css';
import React, { useState, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom';
import DOMPurify from 'dompurify'
import { Error404 } from './404';
import { Loader } from './loading';
import { Lock } from './lock';
import { is_locked, verifyPassword, getPost } from '../api/blog';


const Page = (props) => {
    const location = useLocation();
    const [link, setLink] = useState(undefined);
    const [pageData, setPageData] = useState(undefined);

    const load = async () => {
        // get page to render from url
        let path = location.pathname.split('/').at(-1);
        // remove any get parameters and html id's
        let uid = path.split('?')[0].split('#')[0];
        if (!verifyPassword()) {
            setPageData("locked");
            return;
        }
        try {
            let post = await getPost(uid);
            setPageData(post);
        } catch (error) {
            setPageData("error");
            console.error(error);
        }
    };

    useEffect(() => {
        load();
    }, [location.pathname]);

    if (pageData === undefined) {
        return (
            <Loader/>
        );
    } else if (pageData === "locked") {
        return (
          <Lock link={link} reloadHook={load}/>
        );
    } else if (pageData === "error") {
        return (
            <Error404/>
        );
    } else if (pageData !== "error") {
        return (
            <div className={"lpage"}>
                <title>{pageData.title}</title>
                <div className={"lpage-meta"}>
                    <div className={"lpage-meta-left"}>
                        {pageData.date} <span className={"prevent-select"}>·</span> {pageData.author}
                    </div>
                    <div className={"lpage-meta-right"}>
                        {pageData.tags.map((tag, key) => (<Link to={"/tag/"+tag}><span key={key} className={"prevent-select"}>{tag}</span></Link>))}
                    </div>
                </div>
                <div className={"lpage-title-shadow-container prevent-select no-mobile"}>
                    <h1 className={"lpage-title-shadow-title"}>{pageData.title}</h1>
                </div>
                <div className={"lpage-data"} dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(pageData.html)}}>
                </div>
            </div>
        );
    }
}

export { Page };
