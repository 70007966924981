import './home.css';
import { Link, useLocation } from 'react-router-dom';
import { collect, collect_metadata, collect_b64 } from "../api/page";
import React, { useEffect, useState } from "react";
import { Loader } from "./loading";
import DOMPurify from "dompurify";
import { Error404 } from "./404";

const TagSearch = (props) => {
    const [metaData, setMetaData] = useState(undefined);
    const location = useLocation();
    const [searchTag, setSearchTag] = useState(undefined);

    useEffect(() => {
        // get tag to search for
        let tag = location.pathname.split('/').at(-1);
        // remove any get parameters and html id's
        tag = tag.split('?')[0].split('#')[0];

        const load = async () => {
            try {
                let pages = await collect_metadata("pages");
                let metadata = [];
                for (const page of pages) {
                    let b64 = btoa(page);
                    let page_metadata = await collect_metadata(page);
                    if(tag == "all" || page_metadata.tags.includes(tag)) {
                        page_metadata.link = "/page/" + b64;
                        metadata.push(page_metadata);
                    }
                }
                setMetaData(metadata);
            } catch (error) {
                console.error(error);
                setMetaData("error");
            }
        };
        load();
        setSearchTag(tag);
    }, [location.pathname]);

    if (metaData === undefined) {
        return (
            <Loader/>
        );
    }else if (metaData !== "error") {
        return (
            <div className={"home"}>
                <title>blog.lstuma.dev</title>
                <div className={"home-pages"}>
                    {metaData.map((page, key) => (
                        <div className={"home-page-display"} key={key}>
                            <div className={"home-page-display-meta"}>
                                <div className={"home-page-display-meta-left"}>
                                    <span className={"home-page-display-date"}>{page.date}</span><span
                                    className={"prevent-select no-mobile"}>·</span><span
                                    className={"home-page-display-author no-mobile"}>{page.author}</span>
                                </div>
                                <div className={"home-page-display-tags no-mobile"}>
                                    {page.tags.map((tag, key) => (
                                        <Link to={tag==searchTag?"/tag/all":"/tag/"+tag}><span key={key} className={"home-page-display-tag"+(tag==searchTag?" home-page-display-search-tag":"")}>{tag}</span></Link>
                                    ))}
                                </div>
                            </div>
                                <h2 className={"home-page-display-title"}>
                                    <Link to={page.link} key={key}>{page.title}</Link>
                                </h2>
                            <span className={"home-page-display-description"}>{page.description}</span>
                        </div>
                    ))}
                </div>
            </div>
        );
    } else if (metaData === "error") {
        return (
            <Error404/>
        );
    }
}

export {TagSearch};
