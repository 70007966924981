import './colors.css';
import './vars.css';
import './fonts.css';
import './common/common.css';
import './code.css';
import './App.css';
import './prism.css';
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Navbar } from './common/components';
import { Page } from './pages/page';
import { TagSearch } from './pages/tag';
import { Home } from './pages/home';
import { Error404 } from "./pages/404";
import { Loader } from './pages/loading';
import { Statistics } from './pages/statistics';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Navbar/>
        <Routes>
          <Route path={""} element={<Home />} />
          <Route path={"blog/:page"} element={<Page/>} />
          <Route path={"page/:page"} element={<Page />} />
          <Route path={"tag/:tag"} element={<TagSearch />} />
          <Route path={"statistics"} element={<Statistics />} />
          <Route path={"loader"} element={<Loader />} />
          <Route path={"*"} element={<Error404 />} status={404} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
