import { FaArrowRight } from "react-icons/fa6";
import { GiBossKey } from "react-icons/gi";
import { Chart } from "react-google-charts";
import './statistics.css';
import { gather_stats, save_stats } from "../api/statistics";

import {useEffect, useState} from "react";
import {Pie} from "recharts";


const PageRequests = (props) => {
    console.log(props.data);

    const ip_data = props.data["ip-data"];
    const time_data = props.data["time-data"];
    const chart_options = {
        backgroundColor: {fill: "black"},
        colors: ["#1b9e77", "#d95f02", "#7570b3"],
        hAxis: {
            textStyle:{color: '#FFF'}
        },
        vAxis: {
            textStyle:{color: '#FFF'}
        },
        legend: {
            textStyle:{color: '#FFF'}
        },
        titleTextStyle: {
            color: '#FFF',
        },
    }

    const PieChart = (props) => {
        const data = [
            ["Type", "Requests"],
            ...Object.keys(ip_data).map(ip => [ip.substring(0, 5), ip_data[ip].unauthorized + ip_data[ip].authorized]),
        ];
        const options = {
            title: "Requests (total)",
            ...chart_options
        };
        return (
            <div className={"statistics-url-data-piechart"}>
                <Chart chartType={"PieChart"} data={data} options={options} width={"100%"} height={"100%"}/>
            </div>
        );
    }

    const LineChart = (props) => {
        const data = [
            ["Time", "Requests"],
            ...Object.keys(time_data).map(time => [time, time_data[time]]),
        ];
        const options = {
            title: "Requests per day",
            chartArea: {width: "50%"},
            ...chart_options
        };
        return (
            <div className={"statistics-url-data-linechart"}>
                <Chart chartType={"LineChart"} data={data} options={options} width={"100%"} height={"100%"}/>
            </div>
        );
    }

    const BarChart = (props) => {
        const data = [
            ["Type", "authorizeed", "unauthorized"],
            ...Object.keys(ip_data).map(ip => [ip.substring(0, 5), ip_data[ip].authorized, ip_data[ip].unauthorized]),
        ];
        const options = {
            title: "Requests (authorized/unauthorized)",
            chartArea: {width: "50%"},
            ...chart_options
        };
        return (
            <div className={"statistics-url-data-barchart"}>
                <Chart chartType={"BarChart"} data={data} options={options} width={"100%"} height={"100%"}/>
            </div>
        );
    }

    return (
        <>
            <h2 className={"statistics-url-data-heading"}>{props.url}</h2>
            <div className={"statistics-url-data"}>
                <PieChart url={props.url} data={props.data}/>
                <BarChart url={props.url} data={props.data}/>
                <LineChart url={props.url} data={props.data}/>
            </div>
        </>
    );

}

const Statistics = (props) => {
    const [key, setKey] = useState(null);
    const [data, setData] = useState(null);
    const [msg, setMsg] = useState(null);

    const reload = async (_key) => {
        let stats = null;
        try {
            stats = await gather_stats(_key);
        } catch (e) {
            console.log("failed to gather stats: ", e);
            return false;
        }
        if(stats === null || stats === undefined) return false;

        setData(stats);
        return true;
    }

    const updateKey = async () => {
        let notify_msg = document.querySelector("#msg-notify");
        notify_msg.classList.remove("show");

        console.log("updating key");
        let _key = document.getElementById("statistics-key").value;
        setKey(_key);
        if(!(await reload(_key))) {
            // wrong key or error
            setKey(null);
            setMsg("Incorrect key!");
            notify_msg.classList.add("show");
            return
        }
        // success
        notify_msg.classList.add("success");
        setMsg("Success!");
        notify_msg.classList.add("show");
    }
    if(data===null) {
        return (
            <div className={"cropped-page"}>
                <div className={"statistics-key-container"}>
                    <GiBossKey size={"10.25rem"} className={"statistics-key-icon"}/>
                    <form className={"statistics-key-form"} action={"#"} onSubmit={(event) => {
                        event.preventDefault();
                        updateKey()
                    }}>
                        <input name={"key"} className={"statistics-key"} id={"statistics-key"}
                               placeholder={"●●●●●●●●●●●●●●●●●●●●●●●●●●●●●●●●●●●●●●"} type={"password"}/>
                    </form>
                    <FaArrowRight size={"1.75rem"} className={"statistics-key-submit"} onClick={() => {
                        updateKey();
                    }}/>
                </div>
                <p className={"statistics-key-msg-notify"} id={"msg-notify"}>{msg !== null ? msg : ""}</p>
            </div>
        );
    } else {
        console.log(data)
        return (
            <>
                {Object.keys(data).map(url => <PageRequests url={url} data={data[url]}/>)}
            </>
        );
    }
}

export {Statistics};
