import {replace} from "react-router-dom";

const stats_api_base = "https://api.lstuma.dev:3000/stats";

const gather_stats = async (key) => {
    /*
     * Gather statistics from the API (POST request to stats_api_base)
     */
    let response = await fetch(stats_api_base+"?key="+key, {
        method: "POST",
        headers: {
            "Accept": "application/json",
        },
    })

    if (response.ok) {
        let json = await response.json();
        if (!(json.hasOwnProperty("status_code") && json.status_code !== 200)) {
            return json
        } else {
            console.error("Failed to gather statistics. (invalid key)");
        }
    } else {
        throw new Error("Failed to gather statistics.");
    }
    return null
}

const save_stats = (key) => {
    /*
     * Save statistics to the API (POST request to stats_api_base/save)
     */
    let data = fetch(stats_api_base + "save", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({key: key}),
    }).then((response) => {
        if (response.ok) {
            return response.json();
        } else {
            throw new Error("Failed to save statistics.");
        }
    })

    console.log("save: ", data);
}

export { gather_stats, save_stats };